import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

const Info = ({ prefixIcon, children, text, ...rest }) => {
    return (
        <div style={{ display: "flex" }} {...rest}>
            {children}
            <Tooltip placement="right" title={text} >
                <InfoCircleOutlined style={{ fontSize: '24px', marginLeft: "7px", color: "#D9D9D9" }}></InfoCircleOutlined>
            </Tooltip>
        </div>
    );
}

export default Info;