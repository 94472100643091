import { useEffect, useState } from "react";
import axios from 'axios';
import { List, Button, Result, Empty, Space, Table, ConfigProvider, Modal } from 'antd';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { deleteSync, getSyncs, runSync } from "../../utils/api"
import { CaretRightOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined, PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { UilFolderOpen, UilClock, UilStopwatch, UilArrowRight } from '@iconscout/react-unicons'
import Column from "antd/es/table/Column";
import { flipActiveSync } from "../../utils/endpoints";
import { useNavigate } from "react-router-dom";
import useNotification from "antd/es/notification/useNotification";

const { Item } = List;
const { confirm } = Modal;


const SyncsList = ({ onCreate }) => {
    let navigate = useNavigate();
    const queryClient = useQueryClient();

    const [api, contextHolder] = useNotification();
    const openNotification = (title, message, type) => {
        api.open({
            type: type,
            message: title,
            description: <div>{message}</div>,
            placement: 'bottomRight'
        });
    };

    const [selectedSync, setSelectedSync] = useState();
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleOk = async () => {
        setLoading(true);

        await runSync(selectedSync?.id, false);

        setLoading(false);
        setShowModal(false);
        setSelectedSync(undefined);

        openNotification('Success', 'Sync is generating', 'success');
    };

    const handleCancel = () => {
        setLoading(false);
        setSelectedSync(undefined);
        setShowModal(false);
    };

    const { isError, isSuccess, isLoading, data, error } = useQuery({
        queryKey: ["syncs"],
        queryFn: getSyncs,
        options: { staleTime: 10 * 60 * 100 }
    });
    useEffect(() => {
        queryClient.invalidateQueries();
    }, [])

    const FlipActive = useMutation({
        mutationFn: (ex) =>
            axios
                .post(flipActiveSync(ex.id, !ex.isActive))
                .then((response) => response.data),
        // When mutate is called:
        onMutate: async (ex) => {
            // Cancel any outgoing refetches
            // (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({ queryKey: ['syncs'] })

            // Snapshot the previous value
            const previousSyncs = queryClient.getQueryData(['syncs'])

            // Optimistically update to the new value
            queryClient.setQueryData(['syncs'], (old) => {
                return old.map(e => e.id === ex.id ? { ...e, isActive: !e.isActive } : e);
            });

            // Return a context object with the snapshotted value
            return { previousSyncs }
        },
        // If the mutation fails,
        // use the context returned from onMutate to roll back
        onError: (err, newTodo, context) => {
            queryClient.setQueryData(['syncs'], context.previousSyncs)
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['syncs'] })
        },
    })

    const DeleteSync = useMutation({
        mutationFn: deleteSync,
        // When mutate is called:
        onMutate: async (ex) => {
            // Cancel any outgoing refetches
            // (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({ queryKey: ['syncs'] })

            // Snapshot the previous value
            const previousSyncs = queryClient.getQueryData(['syncs'])

            // Optimistically update to the new value
            queryClient.setQueryData(['syncs'], (old) => {
                return old.filter(e => e.id !== ex.id);
            });

            // Return a context object with the snapshotted value
            return { previousSyncs }
        },
        // If the mutation fails,
        // use the context returned from onMutate to roll back
        onError: (err, newTodo, context) => {
            queryClient.setQueryData(['syncs'], context.previousSyncs)
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['syncs'] })
        },
    })

    const HorizontalCell = ({ children }) => (<div style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "row",
    }}>
        {children}
    </div>);
    const VerticalCell = ({ children }) => (<div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "start",
        flexDirection: "column",
    }}>
        {children}
    </div>);

    return (<>
        {contextHolder}
        <ConfigProvider renderEmpty={() => <Empty style={{ marginTop: "80px" }}
            description={
                <span>
                    You have no syncs yet
                </span>
            }
        >
            <Button type="primary" onClick={onCreate}>Create Now</Button>
        </Empty>}>
            <Modal
                open={showModal}
                title="Are you sure?"
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Back
                    </Button>,
                    <Button
                        key="link"
                        type="primary"
                        danger
                        loading={loading}
                        onClick={async () => {
                            setLoading(true);

                            await runSync(selectedSync?.id, true);

                            setLoading(false);
                            setShowModal(false);
                            setSelectedSync(undefined);

                            openNotification('Success', 'Sync is generating', 'success');
                        }}
                    >
                        Yes
                    </Button>,
                ]}
            >
                This will trigger sync immediately.
            </Modal>
            <Table dataSource={data} pagination={false} loading={isLoading}>
                <Column title="Name" dataIndex="name" key="name" />

                <Column title="SubSource" dataIndex="subSource" key="subSource" width={110}
                    render={(value) => {
                        return (
                            <VerticalCell>
                                {value}
                            </VerticalCell>
                        )
                    }} />
                <Column title="Last Generated" dataIndex="lastGenerated" key="lastGenerated" width={200}
                    render={(value) => {
                        return (
                            <HorizontalCell>
                                <div style={{ width: "35px" }}>
                                    <UilClock color="#0092E4" size="35"></UilClock>
                                </div>
                                <div style={{ marginLeft: "3px" }}>{value}</div>
                            </HorizontalCell>
                        )
                    }}
                />
                <Column title="Next Generation" dataIndex="nextGenerating" key="nextGenerating"
                    width={300}
                    render={(value) => {
                        return (
                            <HorizontalCell>
                                <div style={{ width: "35px" }}>
                                    <UilStopwatch color="#0092E4" size="35"></UilStopwatch>
                                </div>
                                <div style={{ marginLeft: "3px" }}>{value}</div>
                            </HorizontalCell>
                        )
                    }}
                />

                <Column
                    title="Actions"
                    key="action"
                    render={(text, record) => (
                        <Space size="small">
                            <Button type="primary" style={{ width: "130px" }}
                                icon={<CaretRightOutlined />}
                                onClick={
                                    () => {
                                        setSelectedSync(record);
                                        setShowModal(true);
                                    }}>
                                Run Now
                            </Button>

                            <Button type="primary"
                                icon={
                                    record.isActive ?
                                        <><PauseCircleOutlined /></>
                                        :
                                        <PlayCircleOutlined />
                                }
                                onClick={(e) => FlipActive.mutate(record)}>
                                {
                                    record.isActive ?
                                        <>Pause</>
                                        :
                                        <>Activate</>
                                }
                            </Button>
                            <Button type="primary"
                                icon={
                                    <EditOutlined />
                                }
                                onClick={(e) => navigate(`sync/${record.id}`)}>
                                Edit
                            </Button>
                            <Button type="primary" danger style={{ width: "100px" }}
                                icon={<DeleteOutlined />}
                                onClick={() => {
                                    confirm({
                                        title: 'Please confirm you want to delete this sync.',
                                        icon: <ExclamationCircleOutlined />,
                                        content: 'This sync will be deleted permanently. If you want to stop it from running you can just pause it.',
                                        okText: 'Yes, i want to delete this sync',
                                        okType: 'danger',
                                        cancelText: 'Back',

                                        onOk() {
                                            DeleteSync.mutate(record);
                                        },
                                        onCancel() { },
                                    });
                                }}>
                                Delete
                            </Button>
                        </Space>
                    )}
                />

            </Table>
            {isError && <Result
                status="warning"
                title="Can't download syncs. Please, try later."
            />}
        </ConfigProvider >
    </>);
}

export default SyncsList;