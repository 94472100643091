import React, { ReactNode } from "react";
import { Select, SelectProps } from "antd";
import styled from "styled-components";


const SelectWrapper = styled.div`
  position: relative;
  width:100%;
  // width: calc(100% - 3rem + 8px);
  // margin-left:auto;

  .prefix-icon-wrapper {
    position: absolute;
    z-index: 1;
    width: calc(3rem - 8px);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

 && .ant-select .ant-select-selector {
    padding-left: calc(3rem - 8px);
  }

  .ant-select-selection-search-input
  {
    padding:10px;
    text-indent: calc(3rem - 8px);
  }
`;

const CustomSelect = ({ prefixIcon, children, ...rest }) => {
  return (
    <SelectWrapper>
      {prefixIcon && <div className="prefix-icon-wrapper">{prefixIcon}</div>}
      <Select style={{
        width: "100%", minWidth: "300px"
      }} {...rest}>{children}</Select>
    </SelectWrapper>
  );
};

export default CustomSelect;
