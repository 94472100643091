import { deleteSyncById, getAllSyncs, getColumnsByType, getSyncById, previewSync, productCategories, productProperties, runSyncById, save, subSources } from './endpoints'
import axios from 'axios';

export const getSyncs = async () => {
    var res = await axios.get(getAllSyncs);
    return res.data;
}
export const getSync = async (id) => {
    var res = await axios.get(getSyncById(id));
    return res.data;
}

export const saveSync = async (exp) => {
    var res = await axios.post(save, exp);
    return res.data;
}
export const runSync = async (id, affectTuntime) => {
    var res = await axios.post(runSyncById(id, affectTuntime));
    return res.data;
}

export const deleteSync = async (exp) => {
    var res = await axios.post(deleteSyncById(exp.id));
    return res.data;
}

export const getProductCategories = async () => {
    var res = await axios.get(productCategories);
    return res.data;
}
export const getProductProperties = async () => {
    var res = await axios.get(productProperties);
    return res.data;
}
export const getSubSources = async () => {
    var res = await axios.get(subSources);
    return res.data;
}