import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getSync } from '../../utils/api'; // Ensure this points to the actual function that fetches your sync data


const initialState = {
    id: null,
    name: '',
    description: '',
    isEncrypted: false,
    cron: '0 0 * * *',
    shopifyToken: '',
    shopifyUrl: '', // Initialized shopifyUrl field
    subSource: undefined,
    productCategories: [],
    productCategoriesFilterEnabled: false,
    propertyFilter: {
        isEnabled: false,
        propertyName: undefined,
        propertyValue: '',
        isRegex: false,
        isWholeWord: false,
        isCaseSensitive: false,
    },
    syncPrices: false,
    syncTags: false,
    compareAtPriceProperty: undefined,
    isLoading: false,
    error: null,
    currentPanel: "Basic Settings"
};

// Define the async thunk action for fetching a Sync by ID
export const setSyncById = createAsyncThunk('sync/fetchById', async (id, { rejectWithValue }) => {
    try {
        const response = await getSync(id);
        return response; // Ensure this matches your API's response structure
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

// Create the slice
const syncSlice = createSlice({
    name: 'sync',
    initialState,
    reducers: {
        updateName: (state, action) => {
            state.name = action.payload;
        },
        updateDescription: (state, action) => {
            state.description = action.payload;
        },
        setIsEncrypted: (state, action) => {
            state.isEncrypted = action.payload;
        },
        updateCron: (state, action) => {
            state.cron = action.payload;
        },
        updateShopifyUrl: (state, action) => {
            state.shopifyUrl = action.payload;
        },
        updateShopifyToken: (state, action) => {
            state.shopifyToken = action.payload;
            state.isEncrypted = false;
        },
        updateSubSource: (state, action) => {
            state.subSource = action.payload;
        },
        updateProductCategoriesFilterEnabled: (state, action) => {
            state.productCategoriesFilterEnabled = action.payload;
        },
        updateProductCategories: (state, action) => {
            state.productCategories = action.payload;
        },
        updatePropertyFilter: (state, action) => {
            state.propertyFilter = action.payload;
        },
        updateSyncPrices: (state, action) => {
            state.syncPrices = action.payload;
        },
        updateSyncTags: (state, action) => {
            state.syncTags = action.payload;
        },
        updateCompareAtPriceProperty: (state, action) => {
            state.compareAtPriceProperty = action.payload; // Reducer to handle updates to CompareAtPriceProperty
        },
        setInitial: (state) => {
            return initialState;
        },
        updatePanel: (state, action) => {
            state.currentPanel = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(setSyncById.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(setSyncById.fulfilled, (state, action) => {
                const { id, name, description, isEncrypted, cron, shopifyToken, shopifyUrl, subSource,
                    productCategories, propertyFilter, syncPrices, syncTags, compareAtPriceProperty } = action.payload;
                state.id = id;
                state.name = name;
                state.description = description;
                state.isEncrypted = isEncrypted;
                state.cron = cron;
                state.shopifyUrl = shopifyUrl;
                state.shopifyToken = shopifyToken;
                state.subSource = subSource;
                state.productCategories = productCategories;
                state.productCategoriesFilterEnabled = productCategories.length > 0;
                state.propertyFilter = propertyFilter;
                state.syncPrices = syncPrices;
                state.syncTags = syncTags;
                state.compareAtPriceProperty = compareAtPriceProperty; // Update the state with the fetched value
                state.isLoading = false;
                state.error = null;
            })
            .addCase(setSyncById.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload ? action.payload.message : action.error.message;
            });
    }
});

export const {
    updateName,
    updateDescription,
    setIsEncrypted,
    updateCron,
    updateShopifyUrl,
    updateShopifyToken,
    updateSubSource,
    updateProductCategoriesFilterEnabled,
    updateProductCategories,
    updatePropertyFilter,
    updateSyncPrices,
    updateSyncTags,
    updateCompareAtPriceProperty,
    setInitial,
    updatePanel,
} = syncSlice.actions;


export default syncSlice.reducer;
