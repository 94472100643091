import { configureStore } from '@reduxjs/toolkit';
import authorizationReducer from './authorization/authorizationSlice';
import syncReducer from './SyncEdit/syncEditSlice';

export const store = configureStore({
    reducer: {
        authorization: authorizationReducer,
        sync: syncReducer,
    },
});
