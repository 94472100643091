import { getProductCategories, getProductProperties, getSubSources, saveSync } from "../../utils/api";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import useNotification from "antd/es/notification/useNotification";
import { useEffect, useState } from "react";
import { Cron } from 'react-js-cron'; //https://github.com/xrutayisire/react-js-cron
import 'react-js-cron/dist/styles.css'
import '../../index.css';
import '@vscode/codicons/dist/codicon.css';
import { setInitial, setSyncById, updateCompareAtPriceProperty, updateCron, updateDescription, updateName, updateProductCategories, updateProductCategoriesFilterEnabled, updatePropertyFilter, updateShopifyToken, updateShopifyUrl, updateSubSource, updateSyncPrices, updateSyncTags } from "../../data/SyncEdit/syncEditSlice";
import { Collapse, Input, Select, Space, Button, Table, Modal, Popconfirm, Switch, InputNumber, Spin, Form, Typography, Empty, Card, Checkbox, Tooltip } from 'antd';
import { CheckSquareOutlined, CloseOutlined, CloudServerOutlined, DollarOutlined, EditOutlined, FilterOutlined, FolderOpenOutlined, InboxOutlined, InfoCircleFilled, InfoCircleOutlined, KeyOutlined, LeftOutlined, LinkOutlined, LockOutlined, LoginOutlined, RightOutlined, UserOutlined } from '@ant-design/icons';
import CustomSelect from "./CustomSelect";
import Info from "./Info";

const { TextArea } = Input;
const { Option } = Select;



const Create = () => {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    let { id } = useParams();

    const [basicSettingsForm] = Form.useForm();
    const [cronError, onCronError] = useState()

    const sync = useSelector((state) => state.sync);
    const [api, contextHolder] = useNotification();
    const openNotification = (title, message, type) => {
        api.open({
            type: type,
            message: title,
            description: <div>{message}</div>,
            placement: 'bottomRight'
        });
    };

    useEffect(() => {
        if (id) {
            dispatch(setSyncById(id));
        }
        if (!id) {
            dispatch(setInitial());
        }
    }, [id, dispatch]);

    const [productCategories, setProductCategories] = useState([]);
    const [productProperties, setProductProperties] = useState([]);
    const [subSources, setSubSources] = useState([]);

    useEffect(() => {
        async function fetchData() {
            var pc = await getProductCategories();
            setProductCategories(pc);

            var pp = await getProductProperties();
            setProductProperties(pp);

            var ss = await getSubSources();
            setSubSources(ss)
        }
        fetchData();
    }, []);

    if (sync.isLoading) {

        return <Spin tip="Loading your config" className='absolute-centered' />
    }

    return (<>
        {contextHolder}

        <Form name="Export_name_and_type"
            requiredMark={false}
            autoComplete={false}
            form={basicSettingsForm}
            fields={[
                {
                    "name": [
                        "Sync Name"
                    ],
                    "value": sync.name
                },
                {
                    "name": [
                        "SubSource"
                    ],
                    "value": sync.subSource
                },
                {
                    "name": [
                        "Your store URL"
                    ],
                    "value": sync.shopifyUrl
                },
                {
                    "name": [
                        "Your Shopify Token"
                    ],
                    "value": sync.shopifyToken
                },
                {
                    "name": [
                        "Filter by category"
                    ],
                    "value": sync.productCategoriesFilterEnabled
                },

                {
                    "name": [
                        "ProductCategories"
                    ],
                    "value": sync.productCategories
                },
                {
                    "name": [
                        "Filter By Property"
                    ],
                    "value": sync.propertyFilter.isEnabled
                },
                {
                    "name": [
                        "Filter Property Name"
                    ],
                    "value": sync.propertyFilter.propertyName
                },
                {
                    "name": [
                        "Filter Property Value"
                    ],
                    "value": sync.propertyFilter.propertyValue
                },
                {
                    "name": [
                        "Sync Prices"
                    ],
                    "value": sync.syncPrices
                },
                {
                    "name": [
                        "Compare at price property"
                    ],
                    "value": sync.compareAtPriceProperty
                },
            ]}
            layout="inline"
            onFieldsChange={(_, allFields) => {
                console.log(allFields)
            }}
            onFinish={async (values) => {
                console.log("DONE")
                await saveSync(sync);
                dispatch(setInitial());
                navigate("/");
            }}
            style={{ width: "100%", }}
            onFinishFailed={(errorInfo) => { console.log(errorInfo) }}
        >
            <Card
                style={{
                    width: '50vw',
                    margin: '2px',
                    minWidth: "350px"
                }}
            >
                <div style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between"
                }}>
                    <Popconfirm
                        title="Exit this edit?"
                        description="No changes will be saved"
                        onConfirm={(e) => {
                            e.stopPropagation();
                            dispatch(setInitial());
                            navigate("/");
                        }}
                        onCancel={(e) => { e.stopPropagation() }}
                        okText="Yes"
                        cancelText="No"
                        placement="bottom"
                    >
                        <Button type="primary" icon={<CloseOutlined />} style={{ width: "150px" }} onClick={(e) => { }} >Quit</Button>
                    </Popconfirm>

                    <Button onClick={() => { basicSettingsForm.submit() }} type="primary" style={{ width: "150px" }} icon={<CheckSquareOutlined></CheckSquareOutlined>}>Save</Button>
                </div>


            </Card>
            <Card
                size="default"
                style={{
                    width: '50vw',
                    margin: '2px',
                    minWidth: "350px"
                }}
                title={<div style={{ display: "flex" }}>
                    Name and description
                </div>}
            >
                <div style={{ width: "100%", marginLeft: "auto", marginRight: "auto", display: "flex", flexDirection: "row" }}>
                    <Form.Item
                        name="Sync Name"
                        initialValue={sync.name}
                        style={{
                            flex: 1,
                            marginLeft: 0,
                            marginRight: 0
                        }}
                        help={<></>}
                        rules={[
                            {
                                required: true,
                                message: 'Sync name is required!',
                            },
                        ]}
                    >
                        <Input
                            placeholder="Sync name"
                            size="large"
                            onChange={(event) => dispatch(updateName(event.target.value))}
                        />
                    </Form.Item>
                </div>
                <TextArea
                    style={{
                        width: "100%", height: 120,
                        resize: 'none',
                    }}

                    showCount
                    rows={6}
                    maxLength={500}
                    placeholder="Description"
                    value={sync.description}
                    onChange={(event) => dispatch(updateDescription(event.target.value))}
                />
            </Card>

            <Card
                size="default"
                style={{
                    width: '50vw',
                    margin: '2px',
                    minWidth: "350px"
                }}
                title={<div style={{ display: "flex" }}>
                    Shop info
                </div>}
            >
                <Form.Item
                    name="SubSource"
                    initialValue={sync.subSource}
                    value={sync.subSource}
                    help={<></>}
                    rules={[
                        {
                            required: true,
                            message: 'SubSource is required!',
                        },
                    ]}
                >
                    <Info text={"This is your store SubSource"}>
                        <CustomSelect
                            prefixIcon={<FolderOpenOutlined />}
                            placeholder="SubSource"
                            size="large"
                            value={sync.subSource}
                            onChange={(v) => {
                                dispatch(updateSubSource(v));
                            }}
                        >
                            {subSources.map(l =>
                                <Option value={l} label={l}>
                                    {l}
                                </Option>)
                            }
                        </CustomSelect>
                    </Info>

                </Form.Item>

                <Form.Item
                    name="Your store URL"
                    initialValue={sync.shopifyUrl}
                    value={sync.shopifyUrl}
                    help={<></>}
                    rules={[
                        {
                            required: true,
                            message: 'Your store URL is required!',
                        },
                    ]}
                >
                    <Info text={'This is your store URL in shopname.myshopify.com format'}>
                        <Input prefix={<LinkOutlined />} placeholder="Your store URL" value={sync.shopifyUrl} onChange={(e) => dispatch(updateShopifyUrl(e.target.value))} size="large"></Input>
                    </Info>
                </Form.Item>
                <Form.Item
                    name="Your Shopify Token"
                    initialValue={sync.shopifyToken}
                    value
                    help={<></>}
                    rules={[
                        {
                            required: true,
                            message: 'Your Shopify Token is required!',
                        },
                    ]}
                >
                    <Info text={"This is your store Admin API token"}>
                        <Input.Password prefix={<LockOutlined />} placeholder="Your Shopify Token" value={sync.shopifyToken} onChange={(e) => dispatch(updateShopifyToken(e.target.value))} size="large"></Input.Password>
                    </Info>
                </Form.Item>
            </Card>

            <Card
                size="default"
                style={{
                    width: '50vw',
                    margin: '2px',
                    minWidth: "350px"
                }}
                title={<div style={{ display: "flex" }}>
                    <Form.Item
                        name="Filter by category"
                        initialValue={sync.productCategoriesFilterEnabled}
                        help={<></>}
                        rules={[

                        ]}
                    >
                        <Checkbox style={{ textAlign: "left" }}
                            checked={sync.productCategoriesFilterEnabled}
                            onChange={(e) => { dispatch(updateProductCategoriesFilterEnabled(e.target.checked)) }}
                        >Filter by category</Checkbox>
                    </Form.Item>
                </div>}
            >
                <Form.Item
                    name="ProductCategories"
                    initialValue={sync.productCategories}

                    help={<></>}
                    rules={[

                    ]}
                >
                    <Info text={"Choose which product categories to sync"}>
                        <CustomSelect
                            prefixIcon={<InboxOutlined />}
                            mode="multiple"
                            allowClear
                            style={{
                                width: '100%',
                                minWidth: "350px"
                            }}
                            disabled={!sync.productCategoriesFilterEnabled}
                            size="large"
                            placeholder="Please select"
                            defaultValue={[]}
                            value={sync.productCategories}
                            onChange={(value) => { dispatch(updateProductCategories(value)) }}
                            options={productCategories.map(pc => { return { value: pc, label: pc }; })}
                        />
                    </Info>
                </Form.Item>
            </Card>


            <Card
                size="default"
                style={{
                    width: '50vw',
                    margin: '2px',
                    minWidth: "350px"
                }}
                title={<div style={{ display: "flex" }}>
                    <Form.Item
                        name="Filter By Property"
                        initialValue={sync.propertyFilter.isEnabled}
                        help={<></>}
                        rules={[

                        ]}
                    >
                        <Checkbox style={{ textAlign: "left" }}
                            checked={sync.propertyFilter.isEnabled}
                            onChange={(e) => { dispatch(updatePropertyFilter({ ...sync.propertyFilter, isEnabled: e.target.checked })) }}
                        >Filter By Property</Checkbox>
                    </Form.Item>
                </div>}
            >
                <Form.Item
                    name="Filter Property Name"
                    initialValue={sync.propertyFilter.propertyName}
                    help={<></>}
                    rules={[
                        {
                            required: sync.propertyFilter.isEnabled,
                            message: 'Please select a filter property',
                        },
                    ]}
                >
                    <Info text={"Choose property to filter products"}>
                        <CustomSelect
                            prefixIcon={<FilterOutlined />}
                            style={{
                                width: '100%',
                                minWidth: "350px"
                            }}
                            disabled={!sync.propertyFilter.isEnabled}
                            showSearch
                            size="large"
                            placeholder="Please select filter property"
                            value={sync.propertyFilter.propertyName}
                            onChange={(value) => { dispatch(updatePropertyFilter({ ...sync.propertyFilter, propertyName: value })) }}
                            options={productProperties.map(pc => { return { value: pc, label: pc }; })}
                        />
                    </Info>
                </Form.Item>

                <div style={{ width: "95%", marginRight: "16px", display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <Form.Item
                        name="Filter Property Value"
                        initialValue={sync.propertyFilter.propertyValue}
                        help={<></>}
                        style={{ flex: 1 }}
                        rules={[
                            {
                                required: sync.propertyFilter.isEnabled,
                                message: 'Please enter a filter property value',
                            },
                        ]}
                    >
                        <Input
                            disabled={!sync.propertyFilter.isEnabled}
                            placeholder={"Please enter value"}

                            value={sync.propertyFilter.propertyValue}
                            onChange={(e) => {
                                dispatch(updatePropertyFilter({ ...sync.propertyFilter, propertyValue: e.target.value }))
                            }}
                            onPressEnter={() => {
                            }}
                            onBlur={() => {
                                console.log("BLUR CONFIRM")
                                // confirm();
                            }}
                            style={{ flex: 1 }}
                            size="large"
                        ></Input>
                    </Form.Item>


                    <Tooltip placement="bottom" title="Case Sensitive">
                        <Switch checkedChildren={<div style={{ marginTop: "3px" }} class='codicon codicon-case-sensitive'></div>} unCheckedChildren={<div style={{ marginTop: "1px" }} class='codicon codicon-case-sensitive'></div>}
                            checked={sync.propertyFilter.isCaseSensitive}
                            disabled={!sync.propertyFilter.isEnabled}
                            onChange={(value) => {
                                dispatch(updatePropertyFilter({ ...sync.propertyFilter, isCaseSensitive: value }))
                            }} />
                    </Tooltip>

                    <Tooltip placement="bottom" title="Exact Search">
                        <Switch checkedChildren={<div style={{ marginTop: "3px" }} class='codicon codicon-whole-word'></div>} unCheckedChildren={<div style={{ marginTop: "1px" }} class='codicon codicon-whole-word'></div>}
                            checked={sync.propertyFilter.isWholeWord}
                            disabled={!sync.propertyFilter.isEnabled || sync.propertyFilter.isRegex}
                            onChange={(value) => {
                                dispatch(updatePropertyFilter({ ...sync.propertyFilter, isWholeWord: value }))
                            }} />
                    </Tooltip>

                    <Tooltip placement="bottom" title="Advanced filtering language - Regex. You might need an IT specialist to help you with that." >
                        <Switch checkedChildren={<div style={{ marginTop: "3px" }} class='codicon codicon-regex'></div>} unCheckedChildren={<div style={{ marginTop: "1px" }} class='codicon codicon-regex'></div>}
                            checked={sync.propertyFilter.isRegex}
                            disabled={!sync.propertyFilter.isEnabled}
                            onChange={(value) => {
                                dispatch(updatePropertyFilter({ ...sync.propertyFilter, isRegex: value, isWholeWord: false }))
                            }} />
                    </Tooltip>
                </div>
            </Card>
            <Card
                size="default"
                style={{
                    width: '50vw',
                    margin: '2px',
                    minWidth: "350px"
                }}
                title={<div style={{ display: "flex" }}>
                    <Form.Item
                        name="Sync Prices"
                        initialValue={sync.syncPrices}
                        help={<></>}
                        rules={[

                        ]}
                    >
                        <Checkbox style={{ textAlign: "left" }}
                            checked={sync.syncPrices}
                            onChange={(e) => { dispatch(updateSyncPrices(e.target.checked)) }}
                        >Sync Prices</Checkbox>
                    </Form.Item>
                </div>}
            >
                <Form.Item
                    name="Compare at price property"
                    initialValue={sync.compareAtPriceProperty}
                    help={<></>}
                    style={{ flex: 1 }}
                    rules={[
                    ]}
                >
                    <Info text={'Choose property to take "Compare At" price from'}>
                        <CustomSelect
                            // mode="multiple"
                            // mode=""
                            prefixIcon={<DollarOutlined />}
                            style={{
                                width: '100%',
                                minWidth: "350px"
                            }}
                            disabled={!sync.syncPrices}
                            showSearch
                            allowClear
                            size="large"
                            placeholder='Please select "Compare At" property'
                            value={sync.compareAtPriceProperty}
                            onChange={(value) => { dispatch(updateCompareAtPriceProperty(value)) }}
                            options={productProperties.map(pc => { return { value: pc, label: pc }; })}
                        />
                    </Info>
                </Form.Item>
            </Card>
            <Card
                size="default"
                style={{
                    width: '50vw',
                    margin: '2px',
                    minWidth: "350px"
                }}>
                <div style={{ display: "flex" }}>
                    <Checkbox style={{ textAlign: "left" }}
                        checked={sync.syncTags}
                        onChange={(e) => { dispatch(updateSyncTags(e.target.checked)) }}
                    ><div style={{ fontWeight: "600" }}>Sync Tags</div></Checkbox>
                </div>
            </Card>
        </Form >
        <Card
            size="default"
            style={{
                width: '50vw',
                margin: '2px',
                minWidth: "350px"
            }}
            title={<div style={{ display: "flex" }}>
                Schedule
            </div>}
        >
            <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", height: "32px" }}>
                    <Cron
                        allowedPeriods={[
                            'year',
                            'month',
                            'week',
                            'day',
                            'hour',
                        ]}
                        allowedDropdowns={[...[
                            'period',
                            'months',
                            'hours',
                        ],
                        ...(sync.cron.split(' ')[2] == "*" ? ['week-days'] : []),
                        ...(sync.cron.split(' ')[4] == "*" ? ['month-days'] : [])
                        ]}
                        value={sync.cron}
                        setValue={(v) => dispatch(updateCron(v))}
                        onError={onCronError}
                        clearButton={false}
                        humanizeLabels
                        humanizeValue
                        leadingZero
                        locale={{
                            prefixPeriod: 'Runs every ',
                        }}
                        clockFormat="24-hour-clock"
                    />
                </div>
                :00
            </div>
        </Card>

    </>);
}

export default Create;