import { Button, List } from "antd";
import SyncsList from "./SyncsList";
import { useNavigate } from 'react-router-dom';

const MainPage = () => {

    let navigate = useNavigate();

    const CreateSync = () => {
        navigate("/sync")
    }

    return (<>
        <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px", marginTop: "10px" }}>
            <Button onClick={CreateSync} type="primary">Create New</Button>
        </div>
        <SyncsList onCreate={CreateSync}></SyncsList>
    </>);
}

export default MainPage;