export const authorize = '/api/Authorization/authorizeByApplication/';
export const getAllSyncs = '/api/Syncs';
export const save = '/api/Syncs/save';
export const flipActiveSync = (id, isActive) => `/api/Syncs/${id}/${isActive}`;
export const deleteSyncById = (id) => `/api/Syncs/delete/${id}`;
export const getSyncById = (id) => `/api/Syncs/${id}`;
export const runSyncById = (id, affectRunTime) => `/api/Syncs/${id}/run/${affectRunTime}`;

export const productCategories = '/api/AccountInfo/ProductCategories';
export const productProperties = '/api/AccountInfo/ProductProperties';
export const subSources = '/api/AccountInfo/SubSources';
