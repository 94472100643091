import logo from './logo.svg';
import './App.css';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/Layouts/MainLayout';
import UnauthorizedLayout from './components/Layouts/UnauthorizedLayout';
import Authenticate from './components/Authentication/Authenticate';
import MainPage from './components/MainPage/MainPage';
import { Spin } from 'antd';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Create from './components/Create/Create';
const queryClient = new QueryClient();

function App() {
  const { isAuthenticated, isLoading } = useSelector((state) => state.authorization);
  if (isLoading) {
    return (<div className="App">
      <Spin tip="Authenticating..." className='absolute-centered' />
    </div>)
  }
  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <Router>
          {isAuthenticated
            ?
            <Layout>
              <Routes>
                <Route exact path='/' element={<MainPage />}>
                </Route>
                <Route exact path='/sync/:id?' element={<Create></Create>}>
                </Route>
                <Route path='*' element={<MainPage />}>
                </Route>
              </Routes>
            </Layout>
            :
            <UnauthorizedLayout>
              <Routes>
                <Route path="*" element={<Authenticate></Authenticate>}>
                </Route>
              </Routes>
            </UnauthorizedLayout>
          }
        </Router>
      </div>
    </QueryClientProvider>
  );
}

export default App;
